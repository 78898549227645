<template>
  <div class="terms">
    <div class="container content has-text-justified" v-html="$t('terms')">
    </div>

  </div>
</template>

<script>

export default {
  name: 'Terms',
  metaInfo(){
    return {
      link: this.$getAlternates('terms'),
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
